div.dataTables_wrapper div.dataTables_filter label {
    margin-right: 10px;
}
div.dataTables_wrapper div.dataTables_filter {
    margin-top: -25px;
}

#datatable_filter {
    margin-top: 15px;
}

#post_text {
    height: 60px;
    width: 100%;
    padding-top: 1rem;
    padding-left: 1rem;
    font-size: 1.2em;
    padding-right: 65px;
    overflow: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    resize: none;
    margin-bottom: 0px;
}

#show_options {
    background-color: #fff;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    display:none;
}

#post_options_layer {
    padding-left: 1rem;
}

#post_button {
    position: absolute;
    top: 10px;
    right: 10px;
}

#post_avatar {
    position: absolute;
    top: 10px;
    left: 10px;
}
html body .content .content-wrapper {
    margin-top: 4rem;
    min-height: 860px;
}
@media (max-width: 575.98px) {
  html body .content .content-wrapper {
    padding: 0 calc(2.2rem - 2rem) 0;
        margin-top: 6rem;
}
}
.overlay-danger.overlay-lighten-2 {
  background: rgba(0, 0, 0, 0.6);
}

.gallery-container {
    width: 100%;
}

.gallery-row {
    display: flex;
    align-items: center;
    justify-content: center;
}
